$(function() {
  // smoothScroll
  $('a[href^="#"]').smoothScroll({
    offset: -50
  });

  // navbar
  $('.navbar #navbars').click(function(){
    $('.navbar #navbars').removeClass('show');
  });

  // grid
  var $container = $('.grid');
  $container.imagesLoaded(function(){
  	$container.masonry({
      columnWidth: ".item",
  		itemSelector: '.item',
      percentPosition: true,
  		isAnimated: true
  	});
  });

  // home
  if ($('body').hasClass('home')) {
    // feature画像 高さ・幅調整
    var windowH = $(window).height(),
        windowW = $(window).width();
    $("#feature li").each(function(){
      var imgH = $('img',this).height(),
          imgW = $('img',this).width();
      if (windowW/windowH > imgW/imgH) {
        $('img',this).css({
          'width':'100%',
          'height':''
        });
      }else{
        $('img',this).css({
        'height':'100vh',
        'width':''
        });
      }
    });
    // スライドショー
    $("#feature ul").slick({
      dots:true,
      centerMode: true,
      centerPadding:'0',
      autoplay:true,
      autoplaySpeed:5000,
      speed:0,
      pauseOnHover: false
    });
  }

  // works-top
  if ($('body').hasClass('works-top')) {
    if(location.hash){
      var urlHash = location.hash.split('#cat')[1];
      $('#works-category').removeClass(function(index, className) {
        return (className.match(/\bcategory-\S+/g) || []).join(' ');
      }).addClass('category-' + urlHash);
      $('#headline p a').removeClass('selected');
      $('#headline p a#cat-' + urlHash).addClass('selected');
      var $catGrid = $('#works-category');
      $catGrid.imagesLoaded(function(){
        $catGrid.masonry({
          itemSelector: '.catall'
        });
      });
      masonryCat();
    }else{
      var $catGrid = $('#works-category');
      $catGrid.imagesLoaded(function(){
        $catGrid.masonry({
          itemSelector: '.catall'
        });
      });
      masonryCat();
    }
    function masonryCat() {
      categoryBtn = $('#headline p').find('a');
      categoryBtn.each(function() {
        $(this).click(function(e){
          var selected = $(this).attr('id').split('-')[1];
          //e.preventDefault();
          categoryBtn.removeClass('selected');
          $(this).addClass('selected');
          $('#works-category').removeClass(function(index, className) {
            return (className.match(/\bcategory-\S+/g) || []).join(' ');
          }).addClass('category-' + selected);
          var $catGrid = $('#works-category');
          $catGrid.imagesLoaded(function(){
            $catGrid.masonry({
              itemSelector: '.catall'
            });
          });
        });
      });
    }
  }

  // works-detail
  if ($('body').hasClass('works-detail')) {
    $('.works-detail .grid').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled:true
      }
    });
    $('.photos img').removeAttr('width height').each(function(){
      if ($(this).parents('div.item').length > 0) {
      } else {
        var imgsrc = $(this).attr("src");
        $(this).wrap('<div class="item"><div class="item-content"><a href="' + imgsrc +'" class="popup_element"></a></div></div>');
      }
    });
  }
});
